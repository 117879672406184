@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poetsen+One:wght@300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.jakartaFont {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.spaceGroteskFont {
  font-family: 'Space Grotesk', sans-serif;
}

.PoetsenOne {
  font-family: 'Poetsen One', sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #0084f0; /* Azul, como los elementos seleccionados */
  border-radius: 0px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #c7c7c7; /* Gris oscuro */
}

.float {
  display: inline-block;
  transition-duration: 0.3s;
  transition-property: transform;

  /* Efecto flotante */
  &:hover {
    transform: translateY(-8px); /* Ajusta el valor según tu preferencia */
  }
}

@keyframes wobble-horizontal {
  16.65% {
    transform: translateX(8px);
  }

  33.3% {
    transform: translateX(-6px);
  }

  49.95% {
    transform: translateX(4px);
  }

  66.6% {
    transform: translateX(-2px);
  }

  83.25% {
    transform: translateX(1px);
  }

  100% {
    transform: translateX(0);
  }
}

.wobble-horizontal {
  display: inline-block;
  transition: transform 0.3s ease-in-out;

  &:hover {
    animation-name: wobble-horizontal;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
  }
}

.color-transition {
  display: inline-block;
  transition: color 1s ease-out 1s; /* La transición de vuelta al color original debe empezar después de 1 segundo y durar 1 segundo */
  color: black;

  &:hover {
    color: var(--hover-color); /* El color aparece instantáneamente al hacer hover */
    transition: color 0s; /* Hace que el color cambie de inmediato al hacer hover */
  }
}
